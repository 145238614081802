import Amplify, { Auth } from 'aws-amplify';

const configs = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'us-east-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COG_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_COG_CLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    authenticationFlowType: 'USER_SRP_AUTH',

    // storage: localStorage,

    cookieStorage: {
      domain: `${process.env.REACT_APP_COG_COOKIE_DOMAIN}`,
      secure: process.env.REACT_APP_DELPHI_ENV !== 'localAuth',
      expires: 1,
    },

    oauth: {
      domain: process.env.REACT_APP_COG_DOMAIN,
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: process.env.REACT_APP_COG_SIGN_IN,
      redirectSignOut: process.env.REACT_APP_COG_SIGN_OUT,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
};

Amplify.configure(configs);
export default Auth;
