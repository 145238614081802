import React, { useState, useContext } from 'react';
import { Formik } from 'formik';
import Auth from '../../Auth';
import { UserContext } from './DBAuthenticator.js';

import Button from '../../components/CustomButtons/Button';
import Tooltip from '@material-ui/core/Tooltip';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Loader from '../../darkblue-ui/Spinners/Loader';

import InfoIcon from '@material-ui/icons/Info';

import {
  LoginButton,
  ShowPasswordText,
  LoginInputGroup,
  ButtonGridItem,
  LoginText,
  HelperText,
} from '../../components/Login/LoginComponents';

import Input from 'darkblue-ui/Inputs/Input.js';

const NewPassword = ({ setView }) => {
  const userSess = useContext(UserContext);
  const { requiredAttributes } = userSess.user.challengeParam;
  const [errorText, setErrorText] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };

  return (
    <Formik
      initialValues={{ password: '', confirmPassword: '' }}
      onSubmit={async (values, { setSubmitting, setFieldValue }) => {
        setSubmitting(true);
        setErrorText(null);
        if (
          values.password === values.confirmPassword &&
          values.password.length >= 16
        ) {
          try {
            const success = userSess.auth.completeNewPassword(
              userSess.user,
              values.password,
            );
            setView('signIn');
          } catch (err) {
            setSubmitting(false);
            console.log(err);
            setErrorText(err);
          }
        } else if (values.password !== values.confirmPassword) {
          setSubmitting(false);
          setErrorText('Passwords do not match!');
        } else if (values.password.length < 16) {
          setSubmitting(false);
          setErrorText('Password must be at least 16 characters long.');
        } else {
          setSubmitting(false);
        }
      }}
    >
      {({ values, errors, handleChange, handleSubmit, isSubmitting }) =>
        isSubmitting ? (
          <GridContainer style={{height: '370px'}} justify="center" alignItems="center">
            <Loader color="#2c6982" />
          </GridContainer>
        ) : (
          <GridContainer
            style={{ width: '375px', marginTop: '30px', marginBottom: '50px' }}
            justify="center"
            alignItems="center"
            direction="column"
          >
            <Tooltip
              id="selector-tooltip"
              title={
                'Passwords should match and be at least 16 characters long'
              }
              placement="top"
            >
              <InfoIcon
                style={{ position: 'absolute', right: '0px', top: '25px' }}
              />
            </Tooltip>
            <form onSubmit={handleSubmit}>
              <GridItem>
                <Input
                  inputProps={{
                    placeholder: 'New Password',
                    type: showPassword ? 'text' : 'password',
                    name: 'password',
                    onChange: handleChange,
                    value: values.password || '',
                  }}
                  style={{ alignSelf: 'center', width: '100%' }}
                />
              </GridItem>
              <GridItem>
                <Input
                  inputProps={{
                    placeholder: 'Confirm Password',
                    type: showPassword ? 'text' : 'password',
                    name: 'confirmPassword',
                    onChange: handleChange,
                    value: values.confirmPassword || '',
                  }}
                  style={{ alignSelf: 'center', width: '100%' }}
                />
                <ShowPasswordText onClick={() => handleShowPassword()}>
                  {showPassword ? 'Hide Password' : 'Show Password'}
                </ShowPasswordText>
              </GridItem>
              <GridItem>
                <p style={{ fontSize: '12px', textAlign: 'center' }}>
                  Password must be at least 16 alphanumeric characters
                </p>
              </GridItem>
              {errorText ? (
                <GridItem>
                  <h3 style={{ color: '#f55a4e' }}>{errorText}</h3>
                </GridItem>
              ) : null}
              <ButtonGridItem>
                <LoginButton type="submit">Submit</LoginButton>
              </ButtonGridItem>
            </form>
          </GridContainer>
        )
      }
    </Formik>
  );
};

export default NewPassword;
