const cleanPhone = (phone) => {
  return phone.replace(/[^\d]/g, '');
};

/**
 * A helper function to validate that a phone number is in the format that Cognito needs
 * @param {*} values the values object from Formik
 * @returns an errors object with an error for "phone" if the "phone" value is present but incorrect
 */
const validateCognitoPhoneNumber = (values) => {
  const errors = {};
  if (values.phone) {
    if (!/^\+[0-9]+$/i.test(values.phone)) {
      errors.phone =
        'Invalid format. Phone number must start with "+" and country code, and contain no other characters i.e. +15557773333';
    }
  }
  return errors;
};

const isValidEmail = (email) => {
  const emailSyntax = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email.search(emailSyntax) >= 0;
};

export { validateCognitoPhoneNumber, isValidEmail, cleanPhone };
