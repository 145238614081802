import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';

const StyledInput = styled.input.attrs(({ autocomplete }) => ({
  autoComplete: autocomplete || 'off',
}))`
  background-color: ${(props) => props.theme.colors.fog};
  box-sizing: border-box;
  min-height: 48px;
  min-width: 300px;
  border: 1px solid
    ${(props) =>
      props.error ? props.theme.colors.danger : props.theme.colors.cloud};
  border-radius: 4px;

  padding: 0px 16px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  color: ${(props) => props.theme.colors.almostBlack};

  &:focus {
    border: 1px solid
      ${(props) =>
        props.error
          ? props.theme.colors.danger
          : props.theme.colors.altPrimary};
  }

  ${(props) =>
    props.up &&
    css`
      padding-top: 8px;
    `}
`;

const TextFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Label = styled.label`
  color: inherit;
  font-size: 14px;
  will-change: transform;
  transition: all 0.3s cubic-bezier(0.06, 0.67, 0.32, 0.82);
  transform: translate(20px, 35px);
  ${(props) =>
    props.up &&
    css`
      font-size: 10px;
      color: ${(props) =>
        props.error
          ? props.theme.colors.danger
          : props.theme.colors.altPrimary};
      transform: translate(8px, 20px);

      ${(props) =>
        props.inactive &&
        css`
          color: ${(props) =>
            props.error
              ? props.theme.colors.danger
              : props.theme.colors.almostBlack};
        `}
    `}
`;

const HelperText = styled.em`
  padding-left: 16px;
  padding-top: 4px;

  ${(props) =>
    props.error &&
    css`
      color: ${(props) => props.theme.colors.danger};
    `}
`;

const Input = (props) => {
  const { label, inputProps, ...rest } = props;
  const { 
    value,
    onChange,
    name,
    placeholder,
    type,
  } = inputProps;
  const inputEl = useRef(null);
  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  return (
    <TextFieldWrapper>
      {label && (
        <Label up={focused || value} inactive={!focused} error={props.error}>
          {label}
        </Label>
      )}

      <StyledInput
        autocomplete={rest.autocomplete}
        up={focused || value}
        id={name}
        placeholder={placeholder}
        type={type}
        error={props.error}
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={onChange}
        innerRef={inputEl}
        {...rest}
      />
      {props.helperText ? (
        <HelperText error={props.error} className="meta">
          {props.helperText}
        </HelperText>
      ) : null}
    </TextFieldWrapper>
  );
};

export default Input;
