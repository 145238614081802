import styled, { css } from 'styled-components';

import Button from '../../components/CustomButtons/Button';
import Background from '../../assets/img/login_background.png';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';

export const LoginButton = styled(Button)`
  &&& {
    background-color: #025CC2;
    border-radius: 20px;
    min-height: 40px;
    min-width: 117px;
    padding: 0px 48px;
    font-family: 'Red Hat Text', sans-serif;
  }

  .MuiButton-label {
    min-height: 16px;
    min-width: 37px;
    color: #F6FBFF;
    font-family: 'Red Hat Text', sans-serif;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    text-transform: none;
  }

  .MuiTouchRipple-root {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
`;

export const ShowPasswordText = styled.p`
  // color: #025CC2;
  color: #67737E;
  text-align: right;
  margin-right: 0px;
  margin-top: 0px;
  font-family: 'Red Hat Text', sans-serif;
  font-size: 12px;
  // padding-right: 60px !important;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const ForgotYourPasswordText = styled.p`
  color: #025CC2;
  text-align: left;
  margin-top: 0px;
  margin-left: 0px;
  font-family: 'Red Hat Text', sans-serif;
  font-size: 12px;
  // padding-right: 60px !important;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const HelperText = styled.p`
  color: ${(props) => props.error ? '#f55a4e' : '#025CC2'};
  font-family: 'Red Hat Text', sans-serif;
  font-size: 12px;
  text-align: center;
  ${(props) => props.actionText ? 
    css`
      &:hover {
      text-decoration: underline;
      cursor: pointer;
    ` : null};
  }
`;

export const ShowPasswordButton = styled(Button)`
  &&& {
    padding: 0px 48px;
    margin-left: auto;
    margin-right: auto;
  }

  .MuiTouchRipple-root {
    margin-top: 0px !important;
  }
`;

export const GradientBackground = styled.div`
  background-image: url(${Background});
  background-size: cover;
  min-height: 100%;

  width: 100%;
  height: auto;

  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
`;

export const LoginContainer = styled(GridContainer)`
  background-color: #FFFFFF;
  border-radius: 20px;
  box-shadow: 0 8px 16px 0 rgba(7,29,54,0.15);
  display: flex;
  // min-height: 100px;
  min-width: 600px;
  flex-direction: column;
`;

export const LoginText = styled.h3`
  width: 100%;
  color: #081525;
  font-family: 'Red Hat Text', sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.15px;
  line-height: 28px;
  text-align: center;
`;

export const LoginInputGroup = styled.div`
  border: ${(props) =>
    props.error ? '2px solid #f55a4e' : '5px solid #2c6982'};
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  border-radius: 5px;
  // padding-right: 10px;
  transition: border 5 00ms;

  &:focus-within {
    border: 5px solid #94d3eb;
    label {
      color: #94d3eb;
    }
  }

  label {
    // color: #2c6982;
    display: inline-block;
    padding-left: 5px;
    padding-right: 10px;
    background-color: #2d3338;
  }

  label.top {
    position: absolute;
    color: #2c6982;
    top: -20px;
    left: 20px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #2d3338;
  }

  input {
    background-color: #2d3338;
    color: white;
    margin-bottom: 5px;
    padding-top: 10px;
    border: none;
    margin-left: 10px;
    min-width: 95%;
    font-size: 18px;
  }
`;

export const RadioInputGroup = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  vertical-align: middle;

  label {
    display: inline-block;
    text-align: right;
    padding-left: 10px;
    // padding-right: 10px;
    font-size: 18px;
    color: #081525;
    font-family: 'Red Hat Text', sans-serif;
  }

  label.top {
    position: absolute;
    color: #2c6982;
    top: -20px;
    left: 20px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #2d3338;
  }

  input {
    border-radius: 100%;
    width: 26px;
    height: 26px;
    background: ${(props) => props.theme.colors.fog};
    transition: all 100ms;
    border: 1px solid
      ${(props) =>
        props.active ? props.theme.colors.primary : props.theme.colors.fog};
    }
  }
`;

export const ButtonGridItem = styled(GridItem)`
  &&& {
    display: flex;
    // padding-right: 0px !important;
    justify-content: center;
    margin-top: 30px;
  }
`;


