import styled from 'styled-components';

import Button from '../../components/CustomButtons/Button';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import Background from '../../assets/img/background-pattern.svg';
import { grayColor, hexToRgb } from '../../assets/jss/material-dashboard-pro-react.js';

export const DBLogo = styled.img`
  height: auto;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
`

export const BSLogo = styled.img`
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  height: auto;
`

export const PoweredBy = styled.p`
  color: #ffffff;
  opacity: 0.5;
  margin: auto;
  padding-bottom: 50px;
  padding-right: 20px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.5;
`

export const LoginBackground = styled.div`
  background-color: #071D36;
  background-image: url(${Background});
  background-size: cover;
  min-height: 100%;

  width: 100%;
  height: auto;

  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  `;

// const dbContainer = {
//     backgroundImage: `url(${Background})`,
//     backgroundColor: '#071D36',
//     backgroundSize: 'cover',
//     minHeight: '100%',
//     width: '100%',
//     height: 'auto',
//     position: 'fixed',
//     top: '0',
//     left: '0',
//     zIndex: '-1'
//   }

const dbContainer = {
  margin: "auto",
  minWidth: "50%"
}
  
const dbFormContainer = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '355px',
  minWidth: '516px',
  boxShadow: '0 8px 16px 0 rgba(7,29,54,0.15)',
  borderRadius: '20px',
}
  
const dbFormSection = {
  backgroundColor: '#ffffff',
  // borderTopLeftRadius: '25px',
  // borderBottomRightRadius: '25px',
  // boxShadow: '0 8px 16px 0 rgba(7,29,54,0.15)',
  // borderRadius: '20px',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '10px !important',
  marginBottom: '10px !important'
}

const dbInput = {
  backgroundColor: '#E6ECF2',
  // marginBottom: '5px',
  // paddingTop: '10px',
  border: '1px solid #99A8B6',
  borderRadius: '4px',
  boxSizing: 'border-box',
  // marginLeft: '10px',
  fontFamily: 'Red Hat Text',
  minWidth: '300px',
  minHeight: '48px',
  fontSize: '14px',
  letterSpacing: '0',
  lineHeight: '18px'
}
  
const dbRadio = {
  backgroundColor: '#E6ECF2',
  borderRadius: '100%',
  width: '26px',
  height: '26px',
  transition: 'all 100ms',
  border: '1px solid'
}
  
const dbSectionHeader = {
  minHeight: '28px',
  minWidth: '72px',
  color: '#081525',
  fontFamily: 'Red Hat Text',
  fontSize: '24px',
  fontWeight: 'bold',
  letterSpacing: '-0.15px',
  lineHeight: '28px',
  textAlign: 'center',
  alignSelf: 'center'
}
  
const dbHint = {
  // alignSelf: 'right',
  minHeight: '15px',
  minWidth: '97px',
  color: '#025CC2',
  fontFamily: 'Red Hat Text',
  fontSize: '12px',
  letterSpacing: '0',
  lineHeight: '15px',
  textAlign: 'right'
}
  
  // const dbInputLabel = {
  //   display: 'inline-block',
  //   minWidth: '268px',
  //   minHeight: '18px',
  //   // paddingLeft: '5px',
  //   // paddingRight: '10px',
  //   // color: '#67737E',
  //   fontFamily: 'Red Hat Text',
  //   fontSize: '14px',
  //   letterSpacing: '0',
  //   lineHeight: '18px'
  // }
  
const dbLoginButton = {
  backgroundColor: '#025CC2',
  padding: '0px 48px',
  minHeight: '40px',
  minWidth: '117px',
  boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(grayColor[0]) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(grayColor[0]) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(grayColor[0]) +
      ', 0.12)',
  borderRadius: '20px',
  position: 'relative',
  margin: '.3125rem 1px'
}
  
const dbAmazonNoNo = {
  display: 'none'
}

  
export const ShowPasswordText = styled.p`
  color: #fff;
  text-align: right;
  padding-right: 60px !important;

  &:hover {
  text-decoration: underline;
  cursor: pointer;
  }
  `;
  
export const dbLoginLabels = {
  en: {
    'Sign In': 'Log in',
    'Sign in to your account': 'Log in',
    'Enter your username': 'Username',
    'Enter your password': 'Password',
    'Forget your password? ': ' ',
    'Reset password': 'Forgot password?'
  }
}

export const darkBlueTheme = {
    container: dbContainer,
    formContainer: dbFormContainer,
    formSection: dbFormSection,
    sectionHeader: dbSectionHeader,
    sectionHeaderContent: dbSectionHeader,
    sectionBody: dbFormSection,
    signInButton: dbAmazonNoNo,
    signInButtonIcon: dbAmazonNoNo,
    signInButtonContent: dbAmazonNoNo,
    strike: dbAmazonNoNo,
    input: dbInput,
    radio: dbRadio,
    // formField: dbInput,
    inputLabel: dbAmazonNoNo,
    button: dbLoginButton,
    hint: dbHint,
    a: dbHint
}