import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { createBrowserHistory } from 'history';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import DBAuthenticator from './views/Login/DBAuthenticator.js';

const App = () => {
  const hist = createBrowserHistory();
  useEffect(() => {
    localStorage.clear();
    // cookieStorage.clear();
    const cookies = Object.entries(Cookies.get())
      .map(([key]) => key)
      .filter(
        (cookie) =>
          cookie.toLowerCase().indexOf('cognito') ||
          cookie.toLowerCase().indexOf('amplify'),
      );
    cookies.forEach((cookie) => {
      Cookies.remove(cookie);
      Cookies.remove(cookie, { domain: '.darkblueintel.com', path: '/' });

      Cookies.remove(cookie, {
        domain: 'staging-app.darkblueintel.com',
        path: '/',
      });
      Cookies.remove(cookie, {
        domain: 'staging-login.darkblueintel.com',
        path: '/',
      });

      Cookies.remove(cookie, { domain: 'login.darkblueintel.com', path: '/' });
      Cookies.remove(cookie, { domain: 'app.darkblueintel.com', path: '/' });
    });
  }, []);

  return (
    <BrowserRouter history={hist}>
      <Switch>
        <Route path="/" render={() => <DBAuthenticator />} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
