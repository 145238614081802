import React, { useState, useEffect, useContext } from 'react';
import { Formik } from 'formik';
import { UserContext } from './DBAuthenticator.js';

import { validateCognitoPhoneNumber } from './helpers';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Loader from '../../darkblue-ui/Spinners/Loader';

import {
  LoginButton,
  LoginInputGroup,
  ButtonGridItem,
  ForgotYourPasswordText,
  LoginText,
  HelperText
} from '../../components/Login/LoginComponents';

import Input from 'darkblue-ui/Inputs/Input.js';

const SetupPhone = ({
  setView,
  setUser,
}) => {
  const [loginFailure, setLoginFailure] = useState(false);
  const [helpText, setHelpText] = useState();
  const [error, setError] = useState(false);
  const [verify, setVerify] = useState(false);
  const userSess = useContext(UserContext);

  const validate = (values) => {
    const errors = {};
    if (!values.phone) {
      errors.phone = 'Required';
    } else if (!/^\+[0-9]+$/i.test(values.phone)) {
      errors.phone =
        'Invalid format. Phone number must start with "+" and country code, and contain no other characters i.e. +15557773333';
    }
    return errors;
  };
  if (verify) {
    return (
      <Formik
      initialValues={{ code: '' }}
      onSubmit={async (values, { setSubmitting, setFieldValue }) => {
        setSubmitting(true);
        setError(false);
        try {
          const success = await userSess.auth.verifyUserAttributeSubmit(
            userSess.user,
            'phone_number',
            values.code,
          );
          console.log(success);
          const attributes = await userSess.auth.userAttributes(userSess.user);
          attributes.map((attr) => {
            userSess.user.attributes[attr.Name] = attr.Value;
          });
          setUser(userSess.user);
          setView('signedIn');
        } catch (err) {
          setError(true);
          setHelpText(err.message);
          setLoginFailure(true);
          setFieldValue('code', '');
        }
      }}
    >
      {({ values, errors, handleChange, handleSubmit, isSubmitting }) =>
        isSubmitting ? (
          <GridContainer style={{height: '370px'}} justify="center" alignItems="center">
            <Loader color="#2c6982" />
          </GridContainer>
        ) : (
          <GridContainer 
            style={{ width: "375px", marginTop: "30px", marginBottom: "50px" }}
            justify="center" 
            alignItems="center" 
            direction="column"
          >
            <GridItem
              style={{ textAlign: 'center', paddingBottom: '40px' }}
              xs={12}
              >
              <LoginText>Setup Phone Number</LoginText>
              <HelperText>Please enter the code sent to the phone number you entered.</HelperText>
            </GridItem>
            <form onSubmit={handleSubmit}>
              <GridItem>
                <Input inputProps={{
                  placeholder: "Code",
                  name: "code",
                  onChange: handleChange,
                  value: values.code,
                  type: "text"
                  }}
                  autocomplete="off"
                  style={{alignSelf: "center", width: "100%"}}
                />
              </GridItem>
              {helpText ? (
                  <GridItem>
                    <h4 style={{ color: error ? '#f55a4e' : '#fff' }}>
                      {helpText}
                    </h4>
                  </GridItem>
              ) : null}
              <ButtonGridItem>
                <LoginButton type="submit">Submit</LoginButton>
              </ButtonGridItem>
            </form>
          </GridContainer>
        )
      }
    </Formik>
    )
  } else {
    return (
      // <>
        <Formik
          initialValues={{ phone: '' }}
          validate={validateCognitoPhoneNumber}
          onSubmit={async (values, { setSubmitting, setFieldValue }) => {
            setSubmitting(true);
            setLoginFailure(false);
            setError(false);
            if (values.phone && validate(values.phone)) {
              try {
                const updateSuccess = await userSess.auth.updateUserAttributes(
                  userSess.user,
                  {
                    phone_number: values.phone,
                  },
                );
                setVerify(true);
              } catch (err) {
                setHelpText(err.message);
                setError(true);
                setLoginFailure(true);
                setFieldValue('phone', '');
              }
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) =>
            isSubmitting ? (
              <GridContainer style={{height: '370px'}} justify="center" alignItems="center">
                <Loader color="#2c6982" />
              </GridContainer>
            ) : (
              <GridContainer 
                style={{ width: "375px", marginTop: "30px", marginBottom: "50px" }}
                justify="center" 
                alignItems="center" 
                direction="column"
              >
                <GridItem
                  style={{ textAlign: 'center', paddingBottom: '40px' }}
                  xs={12}
                  >
                  <LoginText>Setup Phone Number</LoginText>
                  <HelperText>Enter a phone number for password recovery.</HelperText>
                </GridItem>
                <form onSubmit={handleSubmit}>
                  <GridItem>
                    <Input inputProps={{
                      placeholder: "Phone Number",
                      name: "phone",
                      onChange: handleChange,
                      type: "tel",
                      value: values.phone
                      }}
                      style={{alignSelf: "center", width: "100%"}}
                    />
                  </GridItem>
                  {errors.phone && (
                      <GridItem>
                        <HelperText error={errors.phone}>
                          {errors.phone}
                        </HelperText>
                      </GridItem>
                    )}
                    {helpText ? (
                      <GridItem>
                        <HelperText error={error}>
                          {helpText}
                        </HelperText>
                      </GridItem>
                    ) : null}
                  <ButtonGridItem>
                    <LoginButton type="submit">Submit</LoginButton>
                  </ButtonGridItem>
                </form>
                <HelperText
                  actionText={true}
                  onClick={async () => {
                    try {
                      const skipPhone = async () => {
                        // let user = await userSess.auth.currentAuthenticatedUser();
                        let result = await userSess.auth.updateUserAttributes(userSess.user, {
                          'custom:skipPhone': 'true',
                        });
                        const user = await userSess.auth.currentAuthenticatedUser();
                        setUser(user);
                        const attributes = await userSess.auth.userAttributes(userSess.user);
                        // console.log(attributes);
                        attributes.map((attr) => {
                          // console.log(attr);
                          userSess.user.attributes[attr.Name] = attr.Value;
                        });
                        setUser(userSess.user);
                        setView('signedIn');
                        // return result;                        
                      };
                      skipPhone();
                      // const result = await skipPhone();
                      // setView('signedIn');
                    } catch (e) {
                      setHelpText(e.message);
                      setError(true);
                    }
                  }}
                >
                  Skip
                </HelperText>
              </GridContainer>
            )
          }
        </Formik>
    );
  };
};

export default SetupPhone;
