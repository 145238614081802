import React, { useState, useEffect, createContext } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalThemeProvider from '../../darkblue-ui/styles/GlobalThemeProvider/GlobalThemeProvider';
import Auth from '../../Auth';
import { useLocation } from 'react-router-dom';

import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import lightTheme from '../../darkblue-ui/styles/colors/lightTheme';
import DBSignIn from './DBSignIn.js';
import {
  LoginBackground,
  DBLogo,
  BSLogo,
  PoweredBy,
} from '../../darkblue-ui/Login/DBComponents.js';
import DBLogoImg from '../../assets/img/DarkBlue.svg';
import BSLogoImg from '../../assets/img/BluestoneLogo.png';
import { SnackbarProvider } from 'notistack';

import { getUrlFilterParams } from '../../utils';

export const UserContext = createContext({
  user: null,
  authComplete: false,
  auth: null,
});

export const UserProvider = UserContext.Provider;
export const UserConsumer = UserContext.Consumer;

const removeInvalidArrayElements = (val) => {
  if (Array.isArray(val)) {
    return val.filter((v) => v);
  } else {
    return val;
  }
};

const DBAuthenticator = () => {
  // const AuthWrapper = props => {
  const myLocation = useLocation();
  const urlParams = getUrlFilterParams(myLocation.search);
  const target = urlParams.target || 'darkblue';

  const [authComplete, setAuthComplete] = useState(false);
  const [user, setUser] = useState();

  const GetDarkPursuitUrl = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const session = user.signInUserSession;
    const token = session.getIdToken();
    const jwt = token.getJwtToken();

    let resp = await fetch(process.env.REACT_APP_DARKPURSUIT_ENDPOINT, {
      method: 'POST',
      headers: {
        Authorization: jwt,
        'Content-Type': 'application/json',
      },
    }).then(async function(response) {
      const resp = await response.json();
      const url = resp.Message;
      return url;
    });

    return resp;
  };

  const checkUser = async () => {
    try {
      const loggedUser = await Auth.currentAuthenticatedUser();
      if (loggedUser) {
        setUser(loggedUser);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const redirectTo = (authSignInUrl) => {
    return window.location.pathname
      ? `${authSignInUrl + window.location.pathname + window.location.search}`
      : authSignInUrl;
  };

  const setRedirect = async (user) => {
    if (target === 'darkblue') {
      window.location.href = redirectTo(Auth._config.oauth.redirectSignIn);
    } else {
      const darkPursuitUrl = await GetDarkPursuitUrl();
      window.location.href = darkPursuitUrl;
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  if (!authComplete) {
    return (
      <SnackbarProvider>
        <UserProvider value={{ user, authComplete, auth: Auth }}>
          <ThemeProvider theme={lightTheme}>
            <GlobalThemeProvider />
            <LoginBackground />
            <GridContainer
              alignItems="center"
              direction="column"
              justify="center"
            >
              <GridItem style={{ marginTop: '60px', marginBottom: '50px' }}>
                <DBLogo src={DBLogoImg} />
              </GridItem>
              {/* <GridItem> */}
              <GridItem style={{ minWidth: '500px' }}>
                <DBSignIn setUser={setUser} setAuthComplete={setAuthComplete} />
              </GridItem>
              <GridItem
                style={{
                  marginBottom: '24px',
                  marginTop: '100px',
                }}
              >
                <a href="https://www.bluestoneanalytics.com/" target="_blank">
                  <BSLogo src={BSLogoImg} />
                </a>
              </GridItem>
            </GridContainer>
          </ThemeProvider>
        </UserProvider>
      </SnackbarProvider>
    );
  } else {
    setRedirect(user);
    return null;
  }
};

export default DBAuthenticator;
