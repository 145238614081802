import React, { useState, useContext } from 'react';
import { UserContext } from './DBAuthenticator.js';
import { Formik } from 'formik';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Loader from '../../darkblue-ui/Spinners/Loader';

import {
  LoginButton,
  LoginInputGroup,
  ButtonGridItem,
  ForgotYourPasswordText,
  LoginText,
  HelperText
} from '../../components/Login/LoginComponents';

import Input from 'darkblue-ui/Inputs/Input.js';
import Button from 'darkblue-ui/Button/Button.js'; 

const validateEmail = (values) => {
  let error = { email: '' };
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    error.email = 'Invalid email address';
  }
  return error;
};

const SetupEmail = ({
  setView,
  setUser,
}) => {
  const [loginFailure, setLoginFailure] = useState(false);
  const [helpText, setHelpText] = useState();
  const [error, setError] = useState(false);
  const [verify, setVerify] = useState(false);

  const userSess = useContext(UserContext);

  if (verify) {
    return (
      <Formik
        initialValues={{ code: '' }}
        onSubmit={async (values, { setSubmitting, setFieldValue }) => {
          setSubmitting(true);
          setError(false);
          try {
            const success = await userSess.auth.verifyUserAttributeSubmit(
              userSess.user,
              'email',
              values.code,
            );
            console.log(success);
            const attributes = await userSess.auth.userAttributes(userSess.user);
            attributes.map((attr) => {
              userSess.user.attributes[attr.Name] = attr.Value;
            });
            setUser(userSess.user);
            setView('signedIn');
          } catch (err) {
            setError(true);
            setHelpText(err.message);
            setLoginFailure(true);
            setFieldValue('code', '');
          }
        }}
      >
        {({ values, errors, handleChange, handleSubmit, isSubmitting }) =>
          isSubmitting ? (
            <GridContainer style={{height: '370px'}} justify="center" alignItems="center">
              <Loader color="#2c6982" />
            </GridContainer>
          ) : (
            <GridContainer 
              style={{ width: "375px", marginTop: "30px", marginBottom: "50px" }}
              justify="center" 
              alignItems="center" 
              direction="column"
            >
              <GridItem
                style={{ textAlign: 'center', paddingBottom: '40px' }}
                xs={12}
                >
                <LoginText>Setup Email</LoginText>
                <HelperText>Please enter the code sent to the email address you entered.</HelperText>
              </GridItem>
              <form onSubmit={handleSubmit}>
                <GridItem>
                  <Input inputProps={{
                    placeholder: "Code",
                    name: "code",
                    onChange: handleChange,
                    value: values.code,
                    type: "text",
                    }}
                    autocomplete="off"
                    style={{alignSelf: "center", width: "100%"}}
                  />
                </GridItem>
                {helpText ? (
                    <GridItem>
                      <HelperText error={error}>
                        {helpText}
                      </HelperText>
                    </GridItem>
                ) : null}
                <ButtonGridItem>
                  <LoginButton type="submit">Submit</LoginButton>
                </ButtonGridItem>
              </form>
            </GridContainer>
          )
        }
      </Formik>
    )
  } else {
    return (
      // <>
        <Formik
          initialValues={{ email: '' }}
          // validate={validateEmail}
          onSubmit={async (values, { setSubmitting, setFieldValue }) => {
            // console.log('submitting');
            setSubmitting(true);
            setLoginFailure(false);
            setError(false);
            if (values.email && validateEmail(values.email)) {
              // console.log(userSess.user);
              try {
                const updateSuccess = await userSess.auth.updateUserAttributes(
                  userSess.user,
                  {
                    email: values.email,
                  },
                );
                if (updateSuccess) {
                  setVerify(true);
                }
              } catch (err) {
                setHelpText(err.message);
                setError(true);
                setLoginFailure(true);
                setFieldValue('email', '');
              }
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) =>
            isSubmitting ? (
              <GridContainer style={{height: '370px'}} justify="center" alignItems="center">
                <Loader color="#2c6982" />
              </GridContainer>
            ) : (
              <GridContainer 
                style={{ width: "375px", marginTop: "30px", marginBottom: "50px" }}
                justify="center" 
                alignItems="center" 
                direction="column"
              >
                <GridItem
                  style={{ textAlign: 'center', paddingBottom: '40px' }}
                  xs={12}
                  >
                  <LoginText>Setup Email</LoginText>
                  <HelperText>Enter an email address for password recovery.</HelperText>
                </GridItem>
                <form onSubmit={handleSubmit}>
                  <GridItem>
                    <Input inputProps={{
                      placeholder: "Email",
                      name: "email",
                      onChange: handleChange,
                      type: "text",
                      value: values.email
                      }}
                      style={{alignSelf: "center", width: "100%"}}
                    />
                  </GridItem>
                  {errors.email && (
                    <GridItem>
                      <HelperText error={errors.email}>
                        {errors.email}
                      </HelperText>
                    </GridItem>
                  )}
                  {helpText ? (
                    <GridItem>
                      <HelperText error={error}>
                        {helpText}
                      </HelperText>
                    </GridItem>
                  ) : null}
                  <ButtonGridItem>
                    <LoginButton type="submit">Submit</LoginButton>
                  </ButtonGridItem>
                </form>
                <HelperText
                  actionText={true}
                  onClick={async () => {
                    try {
                      const skipEmail = async () => {
                        // let user = await userSess.auth.currentAuthenticatedUser();
                        let result = await userSess.auth.updateUserAttributes(userSess.user, {
                          'custom:skipEmail': 'true',
                        });
                        const attributes = await userSess.auth.userAttributes(userSess.user);
                        // const user = await userSess.auth.currentAuthenticatedUser();
                        // console.log(attributes);
                        attributes.map((attr) => {
                          // console.log(attr);
                          userSess.user.attributes[attr.Name] = attr.Value;
                        });
                        setUser(userSess.user);
                        setView('signedIn');
                        // return result
                      };
                      skipEmail();
                      // const result = await skipEmail();
                      // setView('signedIn');
                    } catch (e) {
                      setHelpText(e.message);
                      setError(true);
                    }
                  }}
                >
                  Skip
                </HelperText>
              </GridContainer>
            )
          }
        </Formik>
    );
  };
};

export default SetupEmail;
