import React, { useState, useEffect, useContext } from 'react';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import { UserContext } from './DBAuthenticator.js';

import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Loader from '../../darkblue-ui/Spinners/Loader';

import {
  LoginButton,
  ShowPasswordText,
  ButtonGridItem,
  ForgotYourPasswordText,
  LoginText,
  HelperText,
} from '../../components/Login/LoginComponents';

import Input from 'darkblue-ui/Inputs/Input.js';
import Button from 'darkblue-ui/Button/Button.js';

// const theme = darkBlueTheme;

const DBLogin = (props) => {
  const { setView, setUser } = props;
  const queryParams = new URLSearchParams(useLocation().search);
  const partnerParams = Array.from(queryParams)
    .map(([key, value]) => {
      return { key, value };
    })
    .filter(({ key }) => {
      return key.indexOf('utm_') > -1;
    });

  const source = queryParams.get('utm_source') || '';

  const baseDemoLink = `https://www.bluestoneanalytics.com/darkblue-trial-request`;

  const partnerMapping = {
    chainalysis: {
      source: 'chainalysis',
      medium: 'db_login',
      content: 'crypto',
      campaign: 'integrator_demo_q121',
    },
    intel_report: {
      source: 'intel_report',
      medium: 'db_login',
      content: 'russia_disinfo',
      campaign: 'demo_q121',
    },
  };

  const partnerDemoLink = (source) => {
    if (source.length && partnerMapping[source]) {
      return Object.entries(partnerMapping[source]).reduce(
        (link, [key, value]) => link + `&${key}=${value}`,
        baseDemoLink + '?',
      );
    } else {
      return baseDemoLink;
    }
  };

  const [error, setError] = useState(false);
  const [helpText, setHelpText] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const userSess = useContext(UserContext);
  const handleShowPassword = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };

  return (
    <Formik
      initialValues={{ username: '', password: '' }}
      onSubmit={async (values, { setSubmitting, setFieldValue }) => {
        setSubmitting(true);
        setError(false);
        try {
          const loggedUser = await userSess.auth.signIn({
            username: values.username,
            password: values.password,
          });
          setUser(loggedUser);
          if (loggedUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
            setView('requireNewPassword');
          } else if (
            loggedUser.challengeName === 'SMS_MFA' ||
            loggedUser.challengeName === 'SOFTWARE_TOKEN_MFA'
          ) {
            setView('confirmSignIn');
          } else if (!loggedUser.attributes['custom:declinedMFA']) {
            setView('setupMFA');
          }
          // else if (!loggedUser.attributes['custom:skipContact']) {
          //   setView('setupContact');
          // }
          else {
            setView('signedIn');
          }
        } catch (err) {
          setError(true);
          if (err.code === 'MFAMethodNotFoundException') {
            setHelpText(
              'There seems to be a problem with your 2-factor authentication. Please contact your administrator.',
            );
          } else if (err.code === 'PasswordResetRequiredException') {
            setView('forgotPassword');
          } else {
            const newErrMessage =
              err.message.indexOf('radix') > -1
                ? 'Invalid username. Note, only lowercase letters are allowed as part of a username.'
                : err.message;
            setHelpText(newErrMessage);
            setFieldValue('password', '');
          }
        }
      }}
    >
      {({ values, handleChange, handleSubmit, isSubmitting }) =>
        isSubmitting ? (
          <GridContainer style={{height: '475px'}} justify="center" alignItems="center">
            <Loader color="#2c6982" />
          </GridContainer>
        ) : (
          <GridContainer
            style={{ marginTop: '30px', marginBottom: '50px' }}
            justify="center"
            alignItems="center"
            direction="column"
          >
            <GridItem
              style={{ textAlign: 'center', paddingBottom: '40px' }}
              xs={12}
            >
              <LoginText>Log In</LoginText>
            </GridItem>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <GridItem style={{ width: '100%', margin: '8px 0px' }}>
                <Input
                  inputProps={{
                    placeholder: 'Username',
                    type: 'username',
                    name: 'username',
                    onChange: handleChange,
                    value: values.username,
                  }}
                  style={{ alignSelf: 'center', width: '100%' }}
                />
              </GridItem>
              <GridItem style={{ width: '100%', margin: '8px 0px' }}>
                <Input
                  inputProps={{
                    placeholder: 'Password',
                    type: showPassword ? 'text' : 'password',
                    name: 'password',
                    onChange: handleChange,
                    value: values.password,
                  }}
                  style={{ alignSelf: 'center', width: '100%' }}
                />
              </GridItem>
              <GridItem style={{ minWidth: '100%' }}>
                <GridContainer alignItems="center">
                  <GridItem xs={6}>
                    <ForgotYourPasswordText
                      onClick={() => setView('forgotPassword')}
                    >
                      Forgot password?
                    </ForgotYourPasswordText>
                  </GridItem>
                  <GridItem xs={6}>
                    <ShowPasswordText onClick={() => handleShowPassword()}>
                      {showPassword ? 'Hide Password' : 'Show Password'}
                    </ShowPasswordText>
                  </GridItem>
                </GridContainer>
              </GridItem>
              {helpText ? (
                <GridItem style={{ minWidth: '100%' }}>
                  <HelperText error={error}>{helpText}</HelperText>
                </GridItem>
              ) : null}
              <ButtonGridItem style={{ minWidth: '100%' }}>
                <LoginButton type="submit">Log in</LoginButton>
              </ButtonGridItem>
            </form>
            <ButtonGridItem style={{ marginTop: '0px' }}>
              <p style={{ textAlign: 'center' }}>
                Need access to DarkBlue to support your investigation?
              </p>
            </ButtonGridItem>
            <ButtonGridItem style={{ marginTop: '5px' }}>
              <a href={partnerDemoLink(source)} target="__blank">
                <Button style={{ outline: 'none' }} secondary={true}>
                  Get Trial Account
                </Button>
              </a>
            </ButtonGridItem>
          </GridContainer>
        )
      }
    </Formik>
  );
};

// const DBSignIn = (props) => {
//   const title = "Log In";
//   const helperText = null;
//   console.log("Props:");
//   console.log(props)
//   return (
//     <LoginContainer alignItems="center" justify="center" direction="column">
//       <GridContainer justify="center" alignItems="center">
//         <GridItem
//           style={{ textAlign: 'center' }}
//         >
//           <LoginText>{title}</LoginText>
//           {helperText ? <HelperText>{helperText}</HelperText> : null}
//         </GridItem>
//       </GridContainer>
//       <DBLogin {...props}/>
//     </LoginContainer>
//   )
// }

export default DBLogin;
