import React, { useState, useContext } from 'react';
import { Formik } from 'formik';
import { UserContext } from './DBAuthenticator.js';

import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Loader from '../../darkblue-ui/Spinners/Loader';

import {
  LoginButton,
  LoginInputGroup,
  ButtonGridItem,
  ForgotYourPasswordText,
  LoginText,
  HelperText
} from '../../components/Login/LoginComponents';

import Input from 'darkblue-ui/Inputs/Input.js'; 

const MFA = ({
  setView,
  setUser
}) => {
  const [loginFailure, setLoginFailure] = useState(false);
  const [helpText, setHelpText] = useState();
  const [error, setError] = useState(false);

  const userSess = useContext(UserContext);

  return (
    <Formik
      initialValues={{ code: '' }}
      onSubmit={async (values, { setSubmitting, setFieldValue }) => {
        setSubmitting(true);
        setLoginFailure(false);
        setError(false);
        try {
          const loggedUser = await userSess.auth.confirmSignIn(
            userSess.user,
            values.code,
            userSess.user.challengeName,
          );
          // console.log(loggedUser);
          const tmpUser = await userSess.auth.currentAuthenticatedUser();
          // console.log('---- current auth user ----')
          // console.log(tmpUser);
          setUser(tmpUser);
          // console.log('---- current userSess user after setUser update ----')
          // console.log(userSess.user);
          userSess.user = tmpUser;
          // console.log('---- current userSess user after tmpUser update ----')
          // console.log(userSess.user);
          setView('signedIn');
        } catch (err) {
          setError(true);
          setHelpText(err.message);
          setLoginFailure(true);
          setFieldValue('code', '');
        }
        // if (!user.attributes.email && !user.attributes['custom:skipEmail']) {
        //   setView('setupEmail');
        // } 
        // else if (!user.attributes.phone_number && user.attributes['custom:skipPhone']) {
        //   setView('setupPhone');
        // } else {
        //   setView('signedIn')
        // }
      }}
    >
      {({ values, errors, handleChange, handleSubmit, isSubmitting }) =>
        isSubmitting ? (
          <GridContainer style={{height: '370px'}} justify="center" alignItems="center">
            <Loader color="#2c6982" />
          </GridContainer>
        ) : (
          <GridContainer 
            style={{ width: "375px", marginTop: "30px", marginBottom: "50px" }}
            justify="center" 
            alignItems="center" 
            direction="column"
          >
            <GridItem
              style={{ textAlign: 'center', paddingBottom: '40px' }}
              xs={12}
              >
              <LoginText>MFA Code</LoginText>
            </GridItem>
            <form onSubmit={handleSubmit}>
              <GridItem>
                <Input inputProps={{
                  placeholder: "Code",
                  name: "code",
                  onChange: handleChange,
                  value: values.code,
                  }}
                  autocomplete="off"
                  style={{alignSelf: "center", width: "100%"}}
                />
              </GridItem>
              {helpText ? (
                  <GridItem>
                    <HelperText error={error}>
                      {helpText}
                    </HelperText>
                  </GridItem>
              ) : null}
              <ButtonGridItem>
                <LoginButton type="submit">Submit</LoginButton>
              </ButtonGridItem>
            </form>
          </GridContainer>
        )
      }
    </Formik>
  );
};

export default MFA;
