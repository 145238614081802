import React, { useState, useEffect, useContext } from 'react';
import QRCode from 'qrcode.react';
import { Formik } from 'formik';
import Auth from '../../Auth';
import { UserContext } from './DBAuthenticator.js';
// import { getAppCreds } from '../../api/Auth.js';
import { SelectMFAType } from 'aws-amplify-react';

import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Loader from '../../darkblue-ui/Spinners/Loader';

import {
  LoginButton,
  LoginInputGroup,
  ButtonGridItem,
  ForgotYourPasswordText,
  LoginText,
  HelperText
} from '../../components/Login/LoginComponents';

import Input from 'darkblue-ui/Inputs/Input.js'; 

const SetupTOTP = ({
  setView,
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [qrStr, setQrStr] = useState();
  const [confirm, setConfirm] = useState(false);
  const [helpText, setHelpText] = useState();
  const [error, setError] = useState(false);
  const userSess = useContext(UserContext);

  const setTOTP = async () => {
    // console.log(user);
    const code = await userSess.auth.setupTOTP(userSess.user);
    const issuer = 'DarkBlue';
    const tmpStr = `otpauth://totp/AWSCognito:DarkBlue?secret=${code}&issuer=${issuer}`;
    setQrStr(tmpStr);
  };

  useEffect(() => {
    if (!qrStr) {
      setTOTP();
    }
  }, [qrStr]);

  return (
    <Formik
      initialValues={{ code: '' }}
      onSubmit={async (values, { setSubmitting, setFieldValue }) => {
        setSubmitting(true);
        setError(false);
        try {
          const success = await userSess.auth.verifyTotpToken(
            userSess.user,
            values.code,
          );
          const totpSuccess = await userSess.auth.setPreferredMFA(
            userSess.user,
            'TOTP',
          );
          setView('signedIn');
        } catch (err) {
          setError(true);
          setHelpText(err.message);
          setFieldValue('code', '');
        }
        // if (
        //   !user.attributes.email &&
        //   !user.attributes.phone_number &&
        //   !user.attributes['custom:skipContact']
        // ) {
        //   setView('setupContact');
        // } else {
        //   setView('signedIn')
        // }
      }}
    >
      {({ values, errors, handleChange, handleSubmit, isSubmitting }) =>
        isSubmitting ? (
          <GridContainer style={{height: '370px'}} justify="center" alignItems="center">
            <Loader color="#2c6982" />
          </GridContainer>
        ) : (
          confirm ? (
            <GridContainer 
              style={{ width: "375px", marginTop: "30px", marginBottom: "50px" }}
              justify="center" 
              alignItems="center" 
              direction="column"
            >
              <GridItem
                style={{ textAlign: 'center', paddingBottom: '40px' }}
                xs={12}
                >
                <LoginText>Setup app-based authentication</LoginText>
                <HelperText>Enter the code from your authenticator app.</HelperText>
              </GridItem>
              <form onSubmit={handleSubmit}>
                <GridItem>
                  <Input inputProps={{
                    placeholder: "Code",
                    name: "code",
                    onChange: handleChange,
                    value: values.code,
                    }}
                    autocomplete="off"
                    style={{alignSelf: "center", width: "100%"}}
                  />
                </GridItem>
                {helpText ? (
                  <GridItem>
                    <HelperText error={error}>
                      {helpText}
                    </HelperText>
                  </GridItem>
                ) : null}
                <ButtonGridItem>
                  <LoginButton type="submit">Submit</LoginButton>
                </ButtonGridItem>
              </form>
            </GridContainer>
          ) : (
            <GridContainer 
              style={{ width: "375px", marginTop: "30px", marginBottom: "50px" }}
              justify="center" 
              alignItems="center" 
              direction="column"
            >
              <GridItem
                style={{ textAlign: 'center', paddingBottom: '40px' }}
                xs={12}
                >
                <LoginText>Setup app-based authentication</LoginText>
                <HelperText error={error}>
                  Scan the QR code below in your MFA app of choice
                </HelperText>
              </GridItem>
              <GridItem>{qrStr ? <QRCode value={qrStr} /> : null}</GridItem>
              <ButtonGridItem>
                <LoginButton
                  onClick={() => {
                    setSubmitting(true);
                    setConfirm(true);
                  }}
                >
                  Enter Code
                </LoginButton>
              </ButtonGridItem>
            </GridContainer>
          )
        )
      }
    </Formik>
  );
};

export default SetupTOTP;
