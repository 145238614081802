import React from 'react';
import { createGlobalStyle } from 'styled-components';

const GlobalThemeProvider = createGlobalStyle`
  html{
    font-family: 'Red Hat Text', sans-serif !important;
  }

  .root{
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
  }

  h1{
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: ${(props) => props.theme.colors.primaryText};
  }

  h2{
    font-size: 20px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.primaryText};
  }

  h3{
    font-size: 18px;
    line-height: 22px;
    color: ${(props) => props.theme.colors.primaryText};
  }

  h4{
    font-size: 10px;
    line-height: 12px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.primaryText};
  }

  p{
    font-size:14px;
    line-height: 18px;
    color: ${(props) => props.theme.colors.primaryText};
  }
  p.bold{
    font-weight: bold;
  }
  p.xl{
    font-size: 20px;
     line-height: 25px;
  }
  p.l{
    font-size: 16px;
     line-height: 20px;
  }
  p.s{
    font-size: 12px;
    line-height: 15px;
  }

  p.no-margin{
    margin: 0px;
  }

  em{
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 12px;
  }

  em.meta{
    font-size: 10px;
    letter-spacing: 0;
    line-height: 12px;
    font-weight: normal;
  }

  mark{
    background-color: ${(props) => props.theme.colors.highlight};
  }

  .primary{
    color: ${(props) => props.theme.colors.primary}
  }

  .underline-on-hover{
    &:hover{
      text-decoration: underline;
    }
  }

  .fit-content{
      width: fit-content !important;
      padding: 0px 8xp;
    }

  button{
    color: ${(props) => props.theme.colors.plume};
    font-family: 'Red Hat Text', sans-serif !important;
    font-weight: bold;
    height: 40px;
    width: 158px;
    min-width: fit-content;
    border-radius: 20px;
    background-color: ${(props) => props.theme.colors.primary};
    transition: all 0.3s;
    cursor: pointer;

    & > :not(div){
      color: ${(props) => props.theme.colors.plume};
      font-family: 'Red Hat Text', sans-serif !important;
      font-weight: bold;
      margin: 0px !important;
    }


  }

  input { 
    &&& .MuiInputBase-input{
      color: ${(props) => props.theme.colors.primaryText};
    }
  }

  .MuiTable-root{
    background-color: ${(props) => props.theme.colors.cardBackground};
    .MuiTableCell-root{
      color: ${(props) => props.theme.colors.cardTitle}
    }
  }

  a{
    color: ${(props) => props.theme.colors.linkText};
  }

`;

export default GlobalThemeProvider;
