import React, { useState, useContext, useEffect } from 'react';
import { SelectMFAType } from 'aws-amplify-react';

import { UserContext } from './DBAuthenticator.js';
import Auth from '../../Auth.js';

import DBLogin from './DBLogin.js';
import NewPassword from './NewPassword.js';
import MFA from './MFA.js';
import SetupMFA from './SetupMFA.js';
import SetupTOTP from './SetupTOTP.js';
//import SetupSMS from './SetupSMS.js';
import SetupPhone from './SetupPhone.js';
import SetupEmail from './SetupEmail.js';
import ForgotPassword from './ForgotPassword';

import Button from '../../darkblue-ui/Button/Button';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Loader from '../../darkblue-ui/Spinners/Loader';

import {
  GradientBackground,
  LoginContainer,
  LoginText,
  HelperText,
} from '../../components/Login/LoginComponents';

const DBSignIn = (props) => {
  const { setUser, setAuthComplete } = props;
  const [view, setView] = useState('signIn');
  // const [user, setUser] = useState();
  // const [FormComponent, setFormComponent] = useState(DBLogin);

  const userSess = useContext(UserContext);

  const Success = () => {
    return <div>Success! you logged in</div>;
  };

  const stateMap = {
    requireNewPassword: NewPassword,
    forgotPassword: ForgotPassword,
    confirmSignIn: MFA,
    signIn: DBLogin,
    signedOut: DBLogin,
    signedIn: Success,
    setupMFA: SetupMFA,
    //setupSMS: SetupSMS,
    setupTOTP: SetupTOTP,
    setupPhone: SetupPhone,
    setupEmail: SetupEmail,
  };

  const switchLogin = (view) => {
    switch (view) {
      case 'signedIn':
        if (
          (userSess.user.preferredMFA ||
            userSess.user.attributes['custom:declinedMFA']) &&
          (userSess.user.attributes.email ||
            userSess.user.attributes['custom:skipEmail']) &&
          (userSess.user.attributes.phone_number ||
            userSess.user.attributes['custom:skipPhone'])
        ) {
          setAuthComplete(true);
          // return stateMap[view]
        } else if (
          !userSess.user.preferredMFA &&
          !userSess.user.attributes['custom:declinedMFA']
        ) {
          return stateMap['setupMFA'];
        } else if (
          !userSess.user.attributes.email &&
          !userSess.user.attributes['custom:skipEmail']
        ) {
          // console.log('email check failed')
          setView('setupEmail');
          return stateMap['setupEmail'];
        } else if (
          !userSess.user.attributes.phone_number &&
          !userSess.user.attributes['custom:skipPhone']
        ) {
          // console.log('phone check failed')
          setView('setupPhone');
          return stateMap['setupPhone'];
        }
      default:
        return stateMap[view];
    }
  };

  const FormComponent = switchLogin(view);

  useEffect(() => {
    const checkUser = async () => {
      try {
        const loggedUser = await userSess.auth.currentAuthenticatedUser();
        // console.log(loggedUser);
        if (loggedUser) {
          setUser(loggedUser);
          // return loggedUser;
        }
      } catch (err) {
        console.log(err);
      }
    };
    checkUser();
    const FormComponent = switchLogin(view);
  }, [view]);

  return (
    <LoginContainer alignItems="center" justify="center" direction="column">
      <FormComponent setView={setView} setUser={setUser} />
    </LoginContainer>
  );
};

export default DBSignIn;
