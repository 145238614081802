import React from 'react';
import styled, { css } from 'styled-components';
import GridContainer from '../../components/Grid/GridContainer';

const RadioGroupContainer = styled(GridContainer)`
  // display: flex;
  // flex-direction: column;
  margin-left: auto;
  margin-right: auto;

  & > * {
    padding: 8px 8px;
  }
`;

const RadioContainer = styled(GridContainer)`
  // display: flex;
  
  label {
    display: inline-block;
    text-align: right;
    padding-left: 10px;
    // padding-right: 10px;
    font-size: 18px;
    color: #081525;
    font-family: 'Red Hat Text', sans-serif;
  }
`;

const HiddenRadio = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledRadio = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 26px;
  height: 26px;
  background: ${(props) => props.theme.colors.fog};
  transition: all 100ms;
  border: 1px solid
    ${(props) =>
      props.active ? props.theme.colors.primary : props.theme.colors.fog};

  ${RadioContainer}:hover {
    border: 1px solid ${(props) => props.theme.colors.altPrimary};
  }
`;

const FilledCircle = styled.div`
  width: 0px;
  height: 0px;
  border-radius: 100%;
  transition: all 200ms linear;
  ${(props) =>
    props.active &&
    css`
      width: 16px;
      height: 16px;
      border-radius: 100%;
      background-color: ${(props) => props.theme.colors.primary};
    `}
`;

const Radio = (props) => {
  const { active, radioLabel, ...rest } = props;
  return (
    <RadioContainer 
      alignItems="center"
      // justify="fle"
      {...rest}
    >
      <HiddenRadio active={active} />
      <StyledRadio active={active}>
        <FilledCircle active={active} />
      </StyledRadio>
      <label>{radioLabel}</label>
    </RadioContainer>
  );
};

const RadioGroup = (props) => {
  const { value, setFieldValue, name, options } = props;

  return (
    <RadioGroupContainer direction="column" justify="center">
      {options.map((option) => {
        return (
          <Radio
            active={option.value === value}
            onClick={() => setFieldValue(name, option.value)}
            radioLabel={option.label}
            id={option.id}
          />
        );
      })}
    </RadioGroupContainer>
  );
};

export default RadioGroup;
