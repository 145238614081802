//Building block colors
const primary = '#025CC2';

const primaryOpacity = 'rgba(2, 92, 194, 0.2)';

const altPrimary = '#2A8DFE';

const altPrimaryOpacity = `rgba(42,141,254,0.51)`;

const almostBlack = '#0A1521';

const navy = '#071D36';

const charcoal = '#67737E';

const charcoalOpacity = 'rgba(103, 115, 126, 0.38)';

const cloud = '#99A8B6';

const fog = '#E6ECF2';

const plume = '#FBFBFB';

const white = '#FFFFFF';

const bloodOrange = '#D99957';

const morganite = '#5D3EB4';

const aqua = '#3E99B4';

const apple = '#9DB43E';

const danger = '#9C382D';

const success = '#47AA71';

const warning = '#BDA035';

const highlight = '#F9EE06';

//---------------------------
//Component Colors
//---------------------------

const sidebar = fog;

const sidebarText = charcoal;

const sidebarActiveText = primary;

const mainBackground = plume;

const cardBackground = white;

const indexTag = '#F3E0CC';

const contentTag = '#BFDCFE';

const cardTitle = navy;

const cardBodyTitle = charcoal;

const cardBody = navy;

const footerTitle = charcoal;

const footerBody = navy;

const primaryText = navy;

const linkText = primary;

export default {
  colors: {
    primary,
    primaryOpacity,
    altPrimary,
    altPrimaryOpacity,
    almostBlack,
    navy,
    charcoal,
    charcoalOpacity,
    cloud,
    fog,
    plume,
    white,
    bloodOrange,
    morganite,
    aqua,
    apple,
    danger,
    success,
    warning,
    highlight,
    sidebar,
    sidebarText,
    sidebarActiveText,
    mainBackground,
    cardBackground,
    indexTag,
    contentTag,
    cardTitle,
    cardBody,
    cardBodyTitle,
    footerTitle,
    footerBody,
    primaryText,
    linkText,
  },
  type: {
    p: {
      xl: { fontSize: '20px', lineHeight: '25px' },
      l: { fontSize: '16px', lineHeight: '20px' },
      m: { fontSize: '14px', lineHeight: '18px' },
      s: { fontSize: '12px', lineHeight: '15px' },
    },
  },
  shadow: {
    sidebar: '0px 2px 4px 0px rgba(14,58,108,1)',
    light: '0px 2px 4px 0px rgba(7,29,54,0.15)',
    medLight: '0px 4px 8px 0px rgba(7,29,54,0.15)',
    medHeavy: '0px 8px 16px 0px rgba(7,29,54,0.15)',
    heavy: '0px 16px 32px 0px rgba(7,29,54,0.15)',
  },
};
