import React, { useState, useEffect, useContext } from 'react';
import { Formik } from 'formik';
import { UserContext } from './DBAuthenticator.js';

import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Loader from '../../darkblue-ui/Spinners/Loader';

import {
  LoginButton,
  RadioInputGroup,
  ButtonGridItem,
  ForgotYourPasswordText,
  LoginText,
} from '../../components/Login/LoginComponents';

import RadioGroup from 'darkblue-ui/Inputs/RadioGroup.js';

const SetupMFA = ({ setView, setUser }) => {
  const [loginFailure, setLoginFailure] = useState(false);
  const userSess = useContext(UserContext);
  const handleSkipMFA = async () => {
    let user = await userSess.auth.currentAuthenticatedUser();
    try {
      let result = await userSess.auth.updateUserAttributes(userSess.user, {
        'custom:declinedMFA': 'true',
      });
      const attributes = await userSess.auth.userAttributes(userSess.user);
      attributes.map((attr) => {
        userSess.user.attributes[attr.Name] = attr.Value;
      });
      setUser(userSess.user);
    } catch (e) {}
  };

  return (
    <Formik
      initialValues={{ mfaType: 'TOTP' }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        setLoginFailure(false);
        try {
          const success = await userSess.auth.setPreferredMFA(
            userSess.user,
            values.mfaType,
          );
        } catch {
          setLoginFailure(true);
        }
        if (values.mfaType === 'TOTP') {
          setView('setupTOTP');
        } //else if (values.mfaType === 'SMS') {
        //setView('setupSMS');
        //}
        else if (values.mfaType === 'NOMFA') {
          handleSkipMFA();
          if (userSess.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            setView('newPassword');
          }
          // else if (!user.attributes.email &&
          //   !user.attributes.phone_number &&
          //   !user.attributes['custom:skipContact']) {
          //   setView('setupContact');
          // }
          else {
            setView('signedIn');
          }
        }
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) =>
        isSubmitting ? (
          <GridContainer style={{height: '370px'}} justify="center" alignItems="center">
            <Loader color="#2c6982" />
          </GridContainer>
        ) : (
          <GridContainer
            style={{ width: '375px', marginTop: '30px', marginBottom: '50px' }}
            justify="center"
            alignItems="center"
            direction="column"
          >
            <GridItem
              style={{ textAlign: 'center', paddingBottom: '40px' }}
              xs={12}
            >
              <LoginText>Preferred MFA Method</LoginText>
            </GridItem>
            <form onSubmit={handleSubmit}>
              <RadioGroup
                name="mfaType"
                setFieldValue={setFieldValue}
                value={values.mfaType}
                options={[
                  { value: 'TOTP', id: 'totp', label: 'App Token' },
                  //{ value: 'SMS', id: 'sms', label: 'SMS' },
                  { value: 'NOMFA', id: 'nomfa', label: 'Do not enable MFA' },
                ]}
              />
              <ButtonGridItem>
                <LoginButton type="submit">Next</LoginButton>
              </ButtonGridItem>
            </form>
          </GridContainer>
        )
      }
    </Formik>
  );
};

export default SetupMFA;
