import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import Auth from '../../Auth';
import { UserContext } from './DBAuthenticator.js';
import InfoIcon from '@material-ui/icons/Info';

import Tooltip from '@material-ui/core/Tooltip';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Loader from '../../darkblue-ui/Spinners/Loader';

import { useSnackbar } from 'notistack';

import {
  LoginButton,
  LoginInputGroup,
  ButtonGridItem,
  LoginText,
  HelperText,
  ShowPasswordText,
} from '../../components/Login/LoginComponents';

import Input from 'darkblue-ui/Inputs/Input.js';

const validEmail = (email) => {
  const emailSyntax = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email.search(emailSyntax) >= 0;
};

const ForgotPassword = ({ setView }) => {
  //const { requiredAttributes } = userSess.user.challengeParam;
  const [errorText, setErrorText] = useState();
  const [sentVerificationCode, setSentVerificationCode] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [confirmedUsername, setConfirmedUsername] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const userSess = useContext(UserContext);
  const handleShowPassword = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };

  // Show Submit new password form
  if (confirmedUsername && sentVerificationCode && verificationCode) {
    return (
      <Formik
        enableReinitialize
        initialValues={{ password: '', confirmPassword: '' }}
        onSubmit={async (values, { setSubmitting, setFieldValue }) => {
          setSubmitting(true);
          setErrorText(null);
          if (
            values.password === values.confirmPassword &&
            values.password.length > 15
          ) {
            try {
              userSess.auth
                .forgotPasswordSubmit(
                  confirmedUsername,
                  verificationCode,
                  values.password,
                )
                .then((data) => {
                  enqueueSnackbar('Password successfully reset!');
                  setView('signIn');
                })
                .catch((err) => {
                  setErrorText(err.message);
                });
            } catch (err) {
              setSubmitting(false);
              console.log(err);
              setErrorText('Other Error');
              // setErrorText("Something went wrong on our end.")
            }
          } else if (values.password !== values.confirmPassword) {
            setSubmitting(false);
            setErrorText('Passwords do no match');
          } else if (values.password.length < 16) {
            setErrorText('Password must be at least 16 characters long.');
            setSubmitting(false);
          } else {
            setSubmitting(false);
          }
        }}
      >
        {({ values, errors, handleChange, handleSubmit, isSubmitting }) =>
          isSubmitting ? (
            <GridContainer style={{height: '370px'}} justify="center" alignItems="center">
              <Loader color="#2c6982" />
            </GridContainer>
          ) : (
            <GridContainer
              style={{
                width: '375px',
                marginTop: '30px',
                marginBottom: '50px',
                position: 'relative',
              }}
              justify="center"
              alignItems="center"
              direction="column"
            >
              <Tooltip
                id="selector-tooltip"
                title={
                  'Passwords should match and be at least 16 characters long'
                }
                placement="top"
              >
                <InfoIcon
                  style={{ position: 'absolute', right: '0px', top: '25px' }}
                />
              </Tooltip>
              <GridItem
                style={{ textAlign: 'center', paddingBottom: '40px' }}
                xs={12}
              >
                <LoginText>Password Recovery</LoginText>
              </GridItem>
              <form autoComplete="off" onSubmit={handleSubmit}>
                <GridItem>
                  <Input
                    inputProps={{
                      placeholder: 'New Password',
                      type: showPassword ? 'text' : 'password',
                      name: 'password',
                      onChange: handleChange,
                      value: values.password || '',
                    }}
                    style={{
                      alignSelf: 'center',
                      width: '100%',
                      marginBottom: '6px',
                    }}
                  />
                </GridItem>
                <GridItem>
                  <Input
                    inputProps={{
                      placeholder: 'Confirm Password',
                      type: showPassword ? 'text' : 'password',
                      name: 'confirmPassword',
                      onChange: handleChange,
                      value: values.confirmPassword || '',
                    }}
                    style={{ alignSelf: 'center', width: '100%' }}
                  />
                  <ShowPasswordText onClick={() => handleShowPassword()}>
                    {showPassword ? 'Hide Password' : 'Show Password'}
                  </ShowPasswordText>
                </GridItem>
                {errorText ? (
                  <GridItem>
                    <h3 style={{ color: '#f55a4e' }}>{errorText}</h3>
                  </GridItem>
                ) : null}
                <ButtonGridItem>
                  <LoginButton type="submit">Submit</LoginButton>
                </ButtonGridItem>
              </form>
            </GridContainer>
          )
        }
      </Formik>
    );
  } else if (sentVerificationCode) {
    //Show account verification code form
    return (
      <Formik
        enableReinitialize
        initialValues={{
          code: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          setVerificationCode(values.code);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, isSubmitting }) =>
          isSubmitting ? (
            <GridContainer style={{height: '370px'}} justify="center" alignItems="center">
              <Loader color="#2c6982" />
            </GridContainer>
          ) : (
            <GridContainer
              style={{
                width: '375px',
                marginTop: '30px',
                marginBottom: '50px',
              }}
              justify="center"
              alignItems="center"
              direction="column"
            >
              <GridItem
                style={{ textAlign: 'center', paddingBottom: '40px' }}
                xs={12}
              >
                <LoginText>Password Recovery</LoginText>
              </GridItem>
              <form autoComplete="off" onSubmit={handleSubmit}>
                <GridItem>
                  <Input
                    inputProps={{
                      placeholder: 'Verification Code',
                      name: 'code',
                      onChange: handleChange,
                      value: values.code,
                    }}
                    style={{ alignSelf: 'center', width: '100%' }}
                  />
                </GridItem>
                {errorText ? (
                  <GridItem>
                    <h3 style={{ color: '#f55a4e' }}>{errorText}</h3>
                  </GridItem>
                ) : null}
                <ButtonGridItem>
                  <LoginButton type="submit">Submit</LoginButton>
                </ButtonGridItem>
                <GridItem>
                  <HelperText onClick={() => setSentVerificationCode(false)}>
                    Go back
                  </HelperText>
                </GridItem>
              </form>
            </GridContainer>
          )
        }
      </Formik>
    );
  } else {
    // Prompt users to enter an email to reset their password
    return (
      <Formik
        enableReinitialize
        initialValues={{ username: '' }}
        onSubmit={async (values, { setSubmitting, setFieldValue }) => {
          setSubmitting(true);
          setErrorText(null);
          if (values.username) {
            try {
              userSess.auth
                .forgotPassword(values.username)
                .then((data) => {
                  setConfirmedUsername(values.username);
                  setSentVerificationCode(true);
                  setSubmitting(false);
                })
                .catch((err) => {
                  setErrorText(err.message);
                  setSubmitting(false);
                });
              //setPasswordChange(true);
            } catch (err) {
              setSubmitting(false);
              console.log(err);
              setErrorText('Other Error');
              // setErrorText("Something went wrong on our end.")
            }
          } else if (!values.username) {
            setSubmitting(false);
            setErrorText('Invalid Username');
          } else {
            setSubmitting(false);
          }
        }}
      >
        {({ values, errors, handleChange, handleSubmit, isSubmitting }) =>
          isSubmitting ? (
            <GridContainer style={{height: '370px'}} justify="center" alignItems="center">
              <Loader color="#2c6982" />
            </GridContainer>
          ) : (
            <GridContainer
              style={{
                width: '375px',
                marginTop: '30px',
                marginBottom: '50px',
              }}
              justify="center"
              alignItems="center"
              direction="column"
            >
              <GridItem
                style={{ textAlign: 'center', paddingBottom: '40px' }}
                xs={12}
              >
                <LoginText>Password Recovery</LoginText>
              </GridItem>
              <form autoComplete="off" onSubmit={handleSubmit}>
                <GridItem>
                  <Input
                    inputProps={{
                      placeholder: 'Enter your username',
                      name: 'username',
                      onChange: handleChange,
                      value: values.username || '',
                    }}
                    style={{ alignSelf: 'center', width: '100%' }}
                  />
                </GridItem>
                {errorText ? (
                  <GridItem>
                    <h3 style={{ color: '#f55a4e' }}>{errorText}</h3>
                  </GridItem>
                ) : null}
                <ButtonGridItem>
                  <LoginButton type="submit">Submit</LoginButton>
                </ButtonGridItem>
                <GridItem style={{ marginTop: '15px' }}>
                  <HelperText onClick={() => setSentVerificationCode(true)}>
                    Already have a verification code?
                  </HelperText>
                </GridItem>
              </form>
              <HelperText actionText={true} onClick={() => setView('signIn')}>
                Back
              </HelperText>
            </GridContainer>
          )
        }
      </Formik>
    );
  }
};

export default ForgotPassword;
