const filterNames = [
  'NotFilter',
  'TimeFilter',
  'RequiredFieldFilter',
  'IndexFilter',
  'SiteNetFilter',
  'DocFilter',
  'SiteTypeFilter',
  'SiteChangedFilter',
  'RiskRankFilter',
  'DocIdFilter',
  'SiteNameFilter',
  'ContentDrugsFilter',
  'LanguageFilter',
  'BoardFilter',
  'ContentTagsFilter',
  'ResponseStatusFilter',
  'LocationFilter',
  'UserIdFilter',
  'ProductNameFilter',
  'ProductIDFilter',
  'ProdDescFilter',
  'EthereumAddressesFilter',
  'MoneroAddressesFilter',
  'LitecoinAddressesFilter',
  'UserFilter',
  'UserTypeFilter',
  'ProfileDrugContentFilter',
  'VendorNameFilter',
  'VendorIdFilter',
  'NameFilter',
  'ProductTypeFilter',
  'PgpFingerPrintsFilter',
  'ShipToFilter',
  'ShipFromFilter',
  'PageFilter',
  'ReviewFilter',
  'RatingFilter',
  'VendorInfoFilter',
  'ThreadIDFilter',
  'TitleFilter',
  'PostIDFilter',
  'PostOriginFilter',
  'AuthorFilter',
  'AuthorDescriptionFilter',
  'RoleFilter',
  'PgpKeyFilter',
  'FileNameFilter',
  'FileTypeFilter',
  'DocSourcePageFilter',
  'LinksFilter',
  'IpAddressesFilter',
  'PhoneNumbersFilter',
  'PageTitleFilter',
  'HiddenServiceFilter',
  'FirstSnapshotFilter',
  'ClassifiedFilter',
  'BitcoinAddressesToggle',
  'BitcoinAddressesFilter',
  'EmailAddressesFilter',
  'TripCodeFilter',
  'AnalyticsIdFilter',
  'ServerFilter',
  'PortsFilter',
  'ActiveServicesFilter',
  'SSHFingerprintFilter',
  'SSHTypeFilter',
  'SSHVersionFilter',
  'TLSSubjectIssuerFilter',
  'TLSVersionFilter',
  'TLSSignatureFilter',
  'TLSFingerprintFilter',
  'JSDetectedFilter',
  'PrivateKeyFilter',
  'ApacheStatusFilter',
  'IndexFilter',
  'TimeFilter',
  'SearchboxComponent',
  'BulkSearchFilter',
  'RestrictedFieldsFilter',
  'target',
  'SiteNameFilter',
  'LanguageFilter',
  'LocationFilter',
  'PageFilter',
  'FileNameFilter',
  'FileTypeFilter',
  'DocSourcePageFilter',
  'LinksFilter',
  'IpAddressesFilter',
  'PhoneNumbersFilter',
  'HiddenServiceFilter',
  'BoardFilter',
  'ThreadIDFilter',
  'TitleFilter',
  'PostIDFilter',
  'PostOriginFilter',
  'AuthorFilter',
  'UserIdFilter',
  'EthereumAddressesFilter',
  'MoneroAddressesFilter',
  'LitecoinAddressesFilter',
  'UserFilter',
  'SelectorBitcoinAddressesFilter',
  'UserTypeFilter',
  'ProfileDrugContentFilter',
  'PgpFingerPrintsFilter',
  'PgpEmailsFilter',
  'BitcoinAddressesFilter',
  'EmailAddressesFilter',
  'AnalyticsIdFilter',
  'NameFilter',
  'SelectorNameFilter',
  'AuthorDescriptionFilter',
  'RoleFilter',
  'RiskRankFilter',
  'VendorNameFilter',
  'VendorIdFilter',
  'ShipToFilter',
  'ShipFromFilter',
  'ReviewFilter',
  'RatingFilter',
  'VendorInfoFilter',
  'ProductNameFilter',
  'ProductIdFilter',
  'ProductTypeFilter',
  'SiteTypeFilter',
  'SiteChangedFilter',
  'ResponseStatusFilter',
  'PageTitleFilter',
  'ServerFilter',
  'PortsFilter',
  'ActiveServicesFilter',
  'SSHFingerprintFilter',
  'SSHTypeFilter',
  'SSHVersionFilter',
  'TLSSubjectIssuerFilter',
  'TLSVersionFilter',
  'TLSSignatureFilter',
  'TLSFingerprintFilter',
  'ApacheServerhttpdConfFilter',
  'ApacheServerhttpdInfoConfFilter',
  'ApacheServerVHostsConfFilter',
  'ApacheServerInfoFilter',
  'TagsFilter',
  'LocationsFilter',
  'SelectorUserNameFilter',
  'DobFilterName',
  'LBCLanguageFilter',
  'PageLinkFilter',
  'TimezoneFilter',
  'WalletIdFilter',
  'PageLinksFilter',
  'FacebookDataRawFilter',
  'PostLinksFilter',
  'SocialMediaFilter',
  'SelectorEmailAddressesFilter',
  'IpAddressFilter',
  'BuyerIdFilter',
  'FromWalletIdFilter',
  'ToWalletIdFilter',
  'FromWalletLabelFilter',
  'ToBitcoinAddressFilter',
  'ToWalletLabelFilter',
  'SellerIdFilter',
  'TransactionIdFilter',
  'BuyerUsernameFilter',
  'sellerUsernameFilter',
  'DescriptionFilter',
  'UserIdsFilter',
  'TransactionCategoryFilter',
  'SenderUsernameFilter',
  'DisputeMessageFilter',
  'CountryCodeFilter',
  'IntroductionFilter',
  'MessageIdFilter',
  'AdvertisementIdFilter',
  'SenderIdFilter',
  'RecipientUsernameFilter',
  'AdvertisementMessageFilter',
  'ConversationIdFilter',
  'RecipientIdFilter',
  'AccountInfoFilter',
  'ThreadIdFilter',
  'AdvertismentTypeFilter',
  'BankNameFilter',
  'CityFilter',
  'CountryFilter',
  'CurrencyFilter',
  'exchangeCity',
  'ExchangeCountryFilter',
  'LocationIdFilter',
  'LocationTextFilter',
  'MessageFilter',
  'OnlineProviderFilter',
  'RequirementsFilter',
  'SelectorPhoneNumberFilter',
  'TradeTypeFilter',
  'TypeFilter',
  'UserNameFilter',
  'ZipCodeFilter',
  'SiteNetFilter',
  'DocFilter',
  'DrugContentTagsFilter',
  'ContentTagsFilter',
  'MessageTypeFilter',
  'FirstSnapshotFilter',
  'BitcoinToggleFilter',
  'JSDetectedFilter',
  'PrivateKeyFilter',
  'ApacheStatusFilter',
  'ClassifiedFilter',
];

const removeInvalidArrayElements = (val) => {
  if (Array.isArray(val)) {
    return val.filter((v) => v);
  } else {
    return val;
  }
};

const getUrlFilterParams = (location) => {
  const params = new URLSearchParams(location);
  let urlState = [];

  params.forEach((value, key) => {
    if (filterNames.includes(key)) {
      const defaultValue = removeInvalidArrayElements(JSON.parse(value));
      if (defaultValue || defaultValue === false || defaultValue === 0) {
        urlState.push([key, defaultValue]);
      }
    }
  });
  return Object.fromEntries(urlState);
};

export { getUrlFilterParams };
