import styled from 'styled-components';
import React from 'react';
import { CirclesToRhombusesSpinner } from 'react-epic-spinners';

const StyledLoader = styled(CirclesToRhombusesSpinner).attrs({
  size: 25,
  animationDuration: 800,
})`
  padding: 20px;

  .atom-spinner .spinner-inner .spinner-line {
    border-color: ${(props) => props.theme.colors.altPrimary} !important;
  }
  * {
    border-color: ${(props) => props.theme.colors.altPrimary} !important;
    color: ${(props) => props.theme.colors.altPrimary} !important;
  }
`;

const Loader = (props) => {
  return <StyledLoader {...props}/>;
};

export default Loader;
